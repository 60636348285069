import React, { useState, useEffect } from 'react';
import Header from './Header';
import MySideBar from './MySideBar';

function Layout({ children }) {
    const [isDark, setIsDark] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

	// Update `isMobile` state based on window width
	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth < 768);
		window.addEventListener("resize", handleResize);
	return () => window.removeEventListener("resize", handleResize);
	}, []);

	const toggleTheme = () => {
		setIsDark(!isDark);
	};

    return (
        <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased">
			<Header />

			<MySideBar />

			<div className="h-full ml-14 mt-14 mb-10 md:ml-64">
				{children}
			</div>
		</div>
    );
}

export default Layout;
