import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import { BASE_URL } from '../../config';
import LoadingSpinner from '../../components/utils/LoadingSpinner';
import ActionButton from '../../components/utils/ActionButton';
import InputFieldDark from '../../components/utils/InputFieldDark';
import SelectFieldDark from '../../components/utils/SelectFieldDark';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../components/PageContainer';


function EditProfilePage() {
    const { t } = useTranslation();
    const { user, profile, setUser, setProfile } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [profileData, setProfileData] = useState({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        height: profile.height,
        height_unit: profile.height_unit,
        weight: profile.weight,
        weight_unit: profile.weight_unit,
        profile_picture: profile.profile_picture,
        address: profile.address || '',
        postcode: profile.postcode || '',
        town: profile.town || '',
        country: profile.country || ''
    });
    const [countries, setCountries] = useState([]);
    const [errors, setErrors] = useState({ user_errors: {}, profile_errors: {} });
    const [previewImage, setPreviewImage] = useState();
    const navigate = useNavigate();

    const handleChange = (e) => {
        setProfileData({
            ...profileData,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axiosInstance.get(`${BASE_URL}/api/countries/`);
                setCountries(response.data);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };

        fetchCountries();
    }, []);

    const handleSave = async () => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('height', profileData.height);
            formData.append('weight', profileData.weight);
            formData.append('email', profileData.email);
            formData.append('first_name', profileData.first_name);
            formData.append('last_name', profileData.last_name);
            formData.append('address', profileData.address);
            formData.append('postcode', profileData.postcode);
            formData.append('town', profileData.town);
            formData.append('country', profileData.country);

            if (profileData.profile_picture instanceof File) {
                formData.append('profile_picture', profileData.profile_picture);
            }

            const response = await axiosInstance.put(`${BASE_URL}/api/profile/update-profile/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                alert(t('profile.update_success'));
                const updatedProfilePicture = response.data.profile.profile_picture || profile.profile_picture;
                setUser({
                    ...user,
                    email: profileData.email,
                    first_name: profileData.first_name,
                    last_name: profileData.last_name,
                });
                setProfile({
                    ...profile,
                    address: profileData.address,
                    postcode: profileData.postcode,
                    town: profileData.town,
                    country: profileData.country,
                    height: profileData.height,
                    weight: profileData.weight,
                    profile_picture: updatedProfilePicture,
                });

                localStorage.setItem(
                    'user',
                    JSON.stringify({
                        ...user,
                        email: profileData.email,
                        first_name: profileData.first_name,
                        last_name: profileData.last_name,
                    })
                );
                localStorage.setItem(
                    'profile',
                    JSON.stringify({
                        ...profile,
                        address: profileData.address,
                        postcode: profileData.postcode,
                        town: profileData.town,
                        country: profileData.country,
                        height: profileData.height,
                        weight: profileData.weight,
                        profile_picture: profileData.profile_picture,
                    })
                );
                navigate('/user-profile');
            } else {
                alert(t('profile.update_failed'));
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrors({
                    user_errors: error.response.data.user_errors || {},
                    profile_errors: error.response.data.profile_errors || {},
                });
            } else {
                alert(t('profile.update_error'));
            }
        } finally {
            setLoading(false);
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProfileData({ ...profileData, profile_picture: file });
            setPreviewImage(URL.createObjectURL(file));
        }
    };

    return (
        <PageContainer>
            <div className="p-6 bg-[#2D2D2D] shadow-lg rounded-md">
                <h2 className="text-2xl font-semibold text-white mb-6">{t('profile.edit_profile')}</h2>
                <div className="space-y-6">
                    <div className="relative w-32 h-32 mx-auto mb-6">
                        <label htmlFor="profile-picture" className="cursor-pointer">
                            <img
                                src={
                                    previewImage
                                    ? previewImage
                                    : profileData.profile_picture
                                    ? `${BASE_URL}/${profileData.profile_picture}`
                                    : 'https://via.placeholder.com/150'
                                }
                                alt={t('profile.profile_picture')}
                                className="w-full h-full rounded-full object-cover hover:opacity-70 transition-opacity"
                            />
                            <input
                                id="profile-picture"
                                type="file"
                                className="hidden"
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                        </label>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                        <InputFieldDark
                            label={t('profile.first_name')}
                            type="text"
                            name="first_name"
                            value={profileData.first_name}
                            onChange={handleChange}
                            error={errors.user_errors.first_name}
                            errorMessage={errors.user_errors.first_name ? errors.user_errors.first_name[0] : ''}
                        />

                        <InputFieldDark
                            label={t('profile.last_name')}
                            type="text"
                            name="last_name"
                            value={profileData.last_name}
                            onChange={handleChange}
                            error={errors.user_errors.last_name}
                            errorMessage={errors.user_errors.last_name ? errors.user_errors.last_name[0] : ''}
                        />

                        <InputFieldDark
                            label={t('profile.email')}
                            type="email"
                            name="email"
                            value={profileData.email}
                            onChange={handleChange}
                            error={errors.user_errors.email}
                            errorMessage={errors.user_errors.email ? errors.user_errors.email[0] : ''}
                        />

                        <InputFieldDark
                            label={t('profile.height')}
                            type="number"
                            name="height"
                            value={profileData.height}
                            onChange={handleChange}
                            error={errors.profile_errors.height}
                            errorMessage={errors.profile_errors.height ? errors.profile_errors.height[0] : ''}
                        />

                        <InputFieldDark
                            label={t('profile.weight')}
                            type="number"
                            name="weight"
                            value={profileData.weight}
                            onChange={handleChange}
                            error={errors.profile_errors.weight}
                            errorMessage={errors.profile_errors.weight ? errors.profile_errors.weight[0] : ''}
                        />

                        <InputFieldDark
                            label={t('profile.address')}
                            type="text"
                            name="address"
                            value={profileData.address}
                            onChange={handleChange}
                            error={errors.profile_errors.address}
                            errorMessage={errors.profile_errors.address ? errors.profile_errors.address[0] : ''}
                        />

                        <InputFieldDark
                            label={t('profile.postcode')}
                            type="text"
                            name="postcode"
                            value={profileData.postcode}
                            onChange={handleChange}
                            error={errors.profile_errors.postcode}
                            errorMessage={errors.profile_errors.postcode ? errors.profile_errors.postcode[0] : ''}
                        />

                        <InputFieldDark
                            label={t('profile.town')}
                            type="text"
                            name="town"
                            value={profileData.town}
                            onChange={handleChange}
                            error={errors.profile_errors.town}
                            errorMessage={errors.profile_errors.town ? errors.profile_errors.town[0] : ''}
                        />

                        <SelectFieldDark
                            label={t('profile.country')}
                            name="country"
                            value={profileData.country}
                            onChange={handleChange}
                            options={countries.map((country) => ({
                                value: country.code,
                                label: country.name
                            }))}
                            error={errors.profile_errors.country}
                            errorMessage={errors.profile_errors.country ? errors.profile_errors.country[0] : ''}
                        />
                    </div>

                    <div className="flex justify-end mt-6 space-x-4">
                        <ActionButton
                            onClick={handleSave}
                            label={loading ? <LoadingSpinner /> : t('general.save')}
                            disabled={loading}
                        />

                        <ActionButton
                            onClick={() => navigate('/user-profile')}
                            label={t('general.cancel')}
                            customStyles="bg-red-600 hover:bg-red-700 text-white px-6 py-2 rounded-md"
                        />
                    </div>
                </div>
            </div>
        </PageContainer>
    );
}

export default EditProfilePage;
