import { useNavigate, Link } from 'react-router-dom';
import React, { useState, useContext, useRef, useEffect } from 'react';
import { AppContext } from '../AppContext';
import { BASE_URL } from '../config';
import ActionButton from './utils/ActionButton';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const LanguageModal = ({ isOpen, onClose }) => {
	const { t } = useTranslation();

	if (!isOpen) return null;

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		onClose();
	};

	return (
		<div className="fixed inset-0 flex items-center justify-center z-50">
			<div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>
			<div className="relative bg-white rounded-md p-6 shadow-lg w-80">
				<div className="flex justify-between items-center mb-4">
					<h2 className="text-lg font-semibold">{t('general.choose_language')}</h2>
					<button onClick={onClose} className="text-gray-500 hover:text-gray-700">
					<i className="fas fa-times"></i>
					</button>
				</div>
				<p className="text-sm text-gray-500 mb-4">{t('general.language_modal_info')}</p>
				<div className="grid grid-cols-2 gap-4">
					<button
					onClick={() => changeLanguage('en')}
					className="flex flex-col items-start px-2 py-1 text-sm hover:bg-gray-100 rounded"
					>
					<span className="font-bold">English</span>
					<span className="text-gray-500">{t('general.language_english')}</span>
					</button>
					<button
					onClick={() => changeLanguage('de')}
					className="flex flex-col items-start px-2 py-1 text-sm hover:bg-gray-100 rounded"
					>
					<span className="font-bold">Deutsch</span>
					<span className="text-gray-500">{t('general.language_german')}</span>
					</button>
					<button
					onClick={() => changeLanguage('fr')}
					className="flex flex-col items-start px-2 py-1 text-sm hover:bg-gray-100 rounded"
					>
					<span className="font-bold">Français</span>
					<span className="text-gray-500">{t('general.language_french')}</span>
					</button>
				</div>
			</div>
		</div>
	);
};

function Header() {
	const { t } = useTranslation();
	const [isModalOpen, setModalOpen] = useState(false);

	const toggleModal = () => {
		setModalOpen(!isModalOpen);
	};

	const closeModal = () => {
		setModalOpen(false);
	};

	const [isDropdownVisible, setDropdownVisible] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);
	const navigate = useNavigate();
	const dropdownRef = useRef(null);
	const { logout, user, profile } = useContext(AppContext);

	const toggleDropdown = () => {
		setDropdownVisible(!isDropdownVisible);
	};

	const handleNewAnalysisClick = () => {
		navigate('/video-upload');
	};

	useEffect(() => {
	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setDropdownVisible(false);
		}
	};

	document.addEventListener('mousedown', handleClickOutside);

	return () => {
		document.removeEventListener('mousedown', handleClickOutside);
	};
	}, []);

	useEffect(() => {
	const handleScroll = () => {
		setIsScrolled(window.scrollY > 50);
	};

	window.addEventListener('scroll', handleScroll);

	return () => {
		window.removeEventListener('scroll', handleScroll);
	};
	}, []);

	return (
		<header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-[#2D2D2D] shadow-md' : 'bg-transparent'}`}>
			<div className="px-4 py-2 flex justify-between text-white">
			<div className="w-1/5">
				<Link to="/home">
				<img src={'/fit-q.svg'} className="w-24 h-auto" alt="Logo" />
				</Link>
			</div>
			<div className="w-4/5 flex justify-end items-center">
				<ActionButton
				onClick={handleNewAnalysisClick}
				label={t('videos.new_analysis')}
				iconClass="fa fa-video"
				/>
				<div className="mx-4 h-6 w-[1px] bg-gray-400"></div>
				<div className="pl-2 relative" ref={dropdownRef} onClick={toggleDropdown}>
				<div className="flex items-center space-x-2 cursor-pointer relative">
					<img
					src={profile.profile_picture ? `${BASE_URL}/${profile.profile_picture}` : 'https://via.placeholder.com/40'}
					alt="Profile"
					className="w-10 h-10 rounded-full object-cover"
					/>
					<span className="text-white text-sm">
					{user.email ? user.email.split('@')[0] : 'User'}
					</span>
					<i className={`fas fa-chevron-down text-white text-sm transition-transform duration-300 ${isDropdownVisible ? 'rotate-180' : ''}`}></i>
				</div>
				{isDropdownVisible && (
					<div className="absolute right-0 mt-3 p-2 w-56 bg-white rounded-md shadow-lg z-50">
					<Link
						to="/my-account"
						className="flex items-center px-4 py-2 text-sm text-[#2D2D2D] hover:bg-gray-100"
					>
						<i className="fas fa-user mr-2"></i>
						<span>{t('general.manage_my_account')}</span>
					</Link>
					<button
						onClick={toggleModal}
						className="flex items-center w-full text-left px-4 py-2 text-sm text-[#2D2D2D] hover:bg-gray-100"
					>
						<i className="fas fa-language mr-2"></i>
						<span>{t('general.language')}</span>
					</button>
					<button
						onClick={logout}
						className="flex items-center w-full text-left px-4 py-2 text-sm text-[#2D2D2D] hover:bg-gray-100"
					>
						<i className="fas fa-sign-out-alt mr-2"></i>
						<span>{t('authentication.logout')}</span>
					</button>
					</div>
				)}
				</div>
			</div>
			</div>
			<LanguageModal isOpen={isModalOpen} onClose={() => closeModal()} />
			<hr />
		</header>
	);
}

export default Header;
