import React from 'react';
import { Link } from 'react-router-dom';

const SidebarLink = ({ to, iconClass, label, isMobile, isHovered, isActive }) => {
    return (
        <li>
            <Link
                to={to}
                className={`relative flex flex-row items-center h-11 focus:outline-none
                    hover:bg-blue-800 dark:hover:bg-[#212121] text-white hover:text-white
                    border-l-4 pr-6
                    ${isActive ? 'bg-[#212121] border-[#212121]' : 'border-transparent hover:border-blue-500 dark:hover:border-gray-800'}`}
            >
                <span className="inline-flex justify-center items-center w-6 ml-4">
                    <i className={`${iconClass} text-lg`}></i>
                </span>
                <span className={`ml-2 text-sm tracking-wide truncate ${isMobile && !isHovered && "hidden"}`}>
                    {label}
                </span>
            </Link>
        </li>
    );
};

export default SidebarLink;
