import React, { useState } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

function StepsChart() {
	const [data] = useState([
		{ name: 'MON', Steps: 54, 'All time average': 48 },
		{ name: 'TUE', Steps: 41, 'All time average': 49 },
		{ name: 'WED', Steps: 120, 'All time average': 88 },
		{ name: 'THU', Steps: 98, 'All time average': 87 },
		{ name: 'FRI', Steps: 24, 'All time average': 67 },
		{ name: 'SAT', Steps: 34, 'All time average': 84 },
		{ name: 'SUN', Steps: 140, 'All time average': 118 },
	]);

  return (
		<ResponsiveContainer width="100%" height={300}>
			<LineChart data={data}>
				<CartesianGrid stroke="#f5f5f5" />
				<XAxis dataKey="name" tick={{ fill: 'white' }} /> {/* Add tick styling as a default */}
				<YAxis tick={{ fill: 'white' }} /> {/* Add default parameter for ticks */}
				<Tooltip />
				<Legend />
				<Line type="monotone" dataKey="Steps" stroke="#8884d8" activeDot={{ r: 8 }} />
				<Line type="monotone" dataKey="All time average" stroke="#82ca9d" />
			</LineChart>
		</ResponsiveContainer>
  );
}

export default StepsChart;
