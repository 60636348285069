import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div>

        </div>
        // <footer className="px-4 py-4 text-white text-center">
        //     <p className='text-sm'>&copy; {currentYear} Fit-Q. All rights reserved.</p>
        // </footer>
    );
};

export default Footer;
