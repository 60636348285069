import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useDynamicTitle() {
    const location = useLocation(); // Get the current route

    useEffect(() => {
        // Mapping object for route titles
        const titleMap = {
            "/home": "Home - Fit-Q / Running Analysis",
            "/login": "Login - Fit-Q / Running Analysis",
            "/register": "Register - Fit-Q / Running Analysis",
            "/video-analysis": "Video Analysis - Fit-Q / Running Analysis",
            "/folder": "Folder - Fit-Q / Running Analysis",
            // Add other routes as needed
        };

        // Find the title based on the current pathname or use a default title
        const currentPath = Object.keys(titleMap).find(key => location.pathname.startsWith(key));
        const title = titleMap[currentPath] || "Fit-Q / Running Analysis"; // Fallback to a default title

        document.title = title; // Set the document title
    }, [location]); // Trigger effect when location changes
}

export default useDynamicTitle;
