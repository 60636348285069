import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from '../AppContext';

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useContext(AppContext);

  if (loading) {
    // Show a loading spinner or placeholder while checking authentication
    return <div>Loading...</div>;
  }


  if (!user) {
    // If user is not logged in, redirect to login page
    return <Navigate to="/login" />;
  }

  // If user is logged in, allow access to the route
  return children;
};

export default ProtectedRoute;
