import React, { useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../axiosInstance';
import { BASE_URL } from '../../config';

const FolderItem = ({ folder, activeFolderMenu, toggleFolderMenu, handleMenuItemClick, onViewFolder, userId }) => {
    const { t } = useTranslation();
    const [isDragging, setIsDragging] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        setIsDragging(false);

        const file = e.dataTransfer.files[0];
        if (file && file.type.startsWith('video/')) {
            await handleUpload(file, folder.id, userId);
        }
    };

    const handleUpload = async (selectedVideo, folderId, userId) => {
        const formData = new FormData();
        formData.append('file', selectedVideo);
        formData.append('folder', folderId);
        formData.append('uploaded_by', userId); // Assuming userId is passed as a prop

        try {
            setIsLoading(true);

            const response = await axiosInstance.post(`${BASE_URL}/api/videos/upload/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 201) {
                alert(t('videos.upload_complete'));
                // Optionally, reload the current folder or trigger an update function
            } else {
                alert(`${t('videos.upload_failed')}: ${response.data.message}`);
            }
        } catch (error) {
            const serverMessage = error.response?.data?.error || error.response?.data?.message || t('videos.upload_error');
            alert(`${t('videos.upload_failed')}: ${serverMessage}`);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div
            key={folder.id}
            className={`relative bg-[#2D2D2D] shadow-md w-full sm:w-[calc(50%-1rem)] md:w-[calc(33%-1rem)] lg:w-[calc(25%-1rem)] xl:w-[calc(20%-1rem)] cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 ${
                isDragging ? 'border-2 border-[#4CAF50]' : ''
            }`}
            onClick={() => onViewFolder(folder.id)}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)' }}
        >
            <div className={`bg-[#2D2D2D] h-40 flex items-center justify-center ${isDragging ? 'bg-opacity-60' : ''}`}>
                <i className="fas fa-folder text-[#4CAF50] text-6xl"></i>
                {isLoading && <div className="absolute inset-0 flex items-center justify-center text-white">{t("general.uploading") }</div>}
            </div>
            <div className="px-4 py-2 bg-[#2D2D2D] shadow-md" style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)' }}>
                <span className="text-sm font-medium text-white block">{folder.name}</span>
                <span className="text-xs text-gray-400 mt-1 block">
                    {t('folder_management.edited')} {folder.updated_at ? formatDistanceToNow(new Date(folder.updated_at), { addSuffix: true }) : t('folder_management.some_time_ago')}
                </span>
            </div>
            <div className="absolute bottom-2 right-2">
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleFolderMenu(folder.id);
                    }}
                    className="text-white p-2 px-3 rounded-full hover:bg-[#212121] focus:outline-none"
                    style={{ transition: 'background-color 0.2s ease' }}
                >
                    <i className="fas fa-ellipsis-v"></i>
                </button>
            </div>
            {activeFolderMenu === folder.id && (
                <div className="absolute bottom-12 right-0 bg-white text-black shadow-lg rounded w-48">
                    <ul className="p-2">
                        <li
                            className="hover:bg-gray-100 p-2 cursor-pointer flex items-center text-[#2D2D2D] w-full"
                            onClick={(e) => handleMenuItemClick('rename', folder.id, 'folder', e)}
                        >
                            <i className="fas fa-edit mr-2"></i>
                            <span className="w-full">{t('folder_management.rename_folder')}</span>
                        </li>
                        <li
                            className="hover:bg-gray-100 p-2 cursor-pointer flex items-center text-[#2D2D2D] w-full"
                            onClick={(e) => handleMenuItemClick('move_to_trash', folder.id, 'folder', e)}
                        >
                            <i className="fas fa-trash mr-2"></i>
                            <span className="w-full">{t('folder_management.move_to_trash')}</span>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default FolderItem;
