// InputFieldDark.js
import React from 'react';

const InputFieldDark = ({
  label,
  type = 'text',
  name,
  value,
  onChange,
  placeholder,
  required = false,
  autoFocus = false,
  error = false,
  errorMessage = ''
}) => {
  return (
    <div>
      {label && <label className="block text-gray-400 text-sm mb-2" htmlFor={name}>{label}</label>}
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        required={required}
        className={`w-full px-4 py-2 border ${error ? 'border-red-500' : 'border-gray-600'} rounded focus:outline-none focus:border-[#4caf50] text-white bg-[#212121]`}
      />
      {error && <p className="text-red-500 text-sm mt-1">{errorMessage}</p>}
    </div>
  );
};

export default InputFieldDark;
