import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { AppContext } from '../AppContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MediaExplorer from '../components/MediaExplorer';
import FolderModal from '../components/FolderModal';
import Modal from '../components/Modal';
import Breadcrumb from '../components/utils/Breadcrumb';
import { BASE_URL } from '../config';
import axiosInstance from '../axiosInstance';
import { useTranslation } from 'react-i18next';

function Home() {
    const { folderId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { setFolderId } = useContext(AppContext);
    const [folders, setFolders] = useState([]);
    const [videos, setVideos] = useState([]);
    const [currentParent, setCurrentParent] = useState(folderId || null);
    const [folderPath, setFolderPath] = useState([{ id: null, name: 'My Dashboard' }]);
    const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        if (folderId) {
            setCurrentParent(folderId);
        } else {
            setCurrentParent(null);
            setFolderPath([{ id: null, name: t('folder_management.dashboard') }]);
        }
    }, [folderId, t]);

    useEffect(() => {
        const fetchFolders = async () => {
            try {
                const url = currentParent === null
                    ? `${BASE_URL}/api/folders/parent/`
                    : `${BASE_URL}/api/folders/parent/${currentParent}/`;

                const response = await axiosInstance.get(url);
                return response.data;
            } catch (error) {
                console.error('Error fetching folders:', error);
                return [];
            }
        };

        const fetchVideos = async () => {
            try {
                const url = currentParent === null
                    ? `${BASE_URL}/api/videos/folder/root/`
                    : `${BASE_URL}/api/videos/folder/${currentParent}/`;

                const response = await axiosInstance.get(url);
                return response.data;
            } catch (error) {
                console.error('Error fetching videos:', error);
                return [];
            }
        };


        const rebuildBreadcrumb = async (folderId) => {
            const path = [];
            let currentFolderId = folderId;
            let currentFolder = folders.find(folder => folder.id === currentFolderId);

            while (currentFolderId) {
                if (!currentFolder) {
                    try {
                        const response = await axiosInstance.get(`${BASE_URL}/api/folders/${currentFolderId}/`);
                        currentFolder = response.data;
                    } catch (error) {
                        console.error(`Error fetching folder ${currentFolderId}:`, error);
                        break;
                    }
                }
                path.push({ id: currentFolder.id, name: currentFolder.name });
                currentFolderId = currentFolder.parent;
                currentFolder = folders.find(folder => folder.id === currentFolderId);
            }
            path.reverse();
            path.unshift({ id: null, name: t('folder_management.dashboard') });
            setFolderPath(path);
        };

        setFolderId(currentParent);

        const fetchData = async () => {
            setLoading(true);
            try {
                const [foldersResponse, videosResponse] = await Promise.all([fetchFolders(), fetchVideos()]);
                setFolders(foldersResponse);
                setVideos(videosResponse);
                rebuildBreadcrumb(currentParent, foldersResponse);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [currentParent, setFolderId]);


    const handleCreateFolder = async (folderData) => {
        try {
            const response = await axiosInstance.post(`${BASE_URL}/api/folders/create/`, {
                ...folderData,
                parent: currentParent
            });

            if (response.status === 201) {
                const newFolder = response.data;
                setFolders((prevFolders) => [...prevFolders, newFolder]);
                setIsFolderModalOpen(false);
            } else {
                throw new Error(t('folder_management.create_folder_error'));
            }
        } catch (error) {
            console.error('Error creating folder:', error);
            if (error.response && error.response.data && error.response.data.name) {
                throw new Error(error.response.data.name);
            }
            throw new Error(t('folder_management.create_folder_error_generic'));
        }
    };

    const handleViewFolder = (folderId) => {
        navigate(`/folder/${folderId}`);
        setCurrentParent(folderId);
    };



    const handleNavigateBreadcrumb = (folderId) => {
        setCurrentParent(folderId);
        const folderIndex = folderPath.findIndex(folder => folder.id === folderId);
        setFolderPath(folderPath.slice(0, folderIndex + 1));
        navigate(folderId === null ? '/home' : `/folder/${folderId}`);
    };

    return (
        <div>
            <Header onCreateFolder={() => setIsFolderModalOpen(true)} />
            <main className='px-5 pt-14'>
                <div className="h-full">
                    {loading ? (
                        <div className="flex justify-center items-center h-full">
                            <svg className="animate-spin h-8 w-8 text-[#4caf50]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                            </svg>
                        </div>
                    ) : (
                        <>
                            <Breadcrumb folderPath={folderPath} onNavigate={handleNavigateBreadcrumb} />
                            <MediaExplorer
                                folders={folders}
                                videos={videos}
                                onViewFolder={handleViewFolder}
                                setFolders={setFolders}
                                setVideos={setVideos}
                            />
                        </>
                    )}
                </div>
                {(location.pathname === '/home' || location.pathname.startsWith('/folder/')) && (
                    <button
                        onClick={() => setIsFolderModalOpen(true)}
                        className="fixed bottom-5 right-5 bg-[#4caf50] text-white shadow-lg hover:bg-[#43a047] transition-all duration-300 flex justify-center items-center"
                        style={{ width: '50px', height: '50px', fontSize: '24px', borderRadius: '10px' }}
                    >+
                    </button>
                )}
            </main>
            {/* <Footer /> */}
            <Modal isOpen={isFolderModalOpen} onClose={() => setIsFolderModalOpen(false)}>
                <FolderModal onCreate={handleCreateFolder} onClose={() => setIsFolderModalOpen(false)} />
            </Modal>
        </div>
    );
}

export default Home;
