import React from 'react';

function Breadcrumb({ folderPath, onNavigate }) {
    return (
        <div className="flex items-center text-sm text-white mt-4">
            {folderPath.map((folder, index) => (
                <React.Fragment key={folder.id}>
                    <button
                        onClick={() => onNavigate(folder.id)}
                        className="hover:underline focus:outline-none"
                    >
                        {folder.name}
                    </button>
                    {index < folderPath.length - 1 && (
                        <span className="mx-2 text-gray-400">/</span>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
}

export default Breadcrumb;
