import React, { createContext, useState, useEffect } from 'react';

// Create a context
export const AppContext = createContext({
	user: null,
	profile: null,  // Store the profile data
	tokens: null,
	folderId: null,
	settings: null,
	loading: true,
	login: () => {},
	logout: () => {},
});

// Create a provider component
export const AppProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [profile, setProfile] = useState(null);  // Add state for profile
	const [tokens, setTokens] = useState(null);
	const [folderId, setFolderId] = useState(null);
	const [loading, setLoading] = useState(true); // Track loading state


	useEffect(() => {
		const storedTokens = localStorage.getItem('tokens');

		const storedUser = localStorage.getItem('user');
		const storedProfile = localStorage.getItem('profile');

		if (storedTokens) {
			setTokens(JSON.parse(storedTokens));
		}
		if (storedUser) {
			setUser(JSON.parse(storedUser)); // Set the user in the state
		}
		if (storedProfile) {
			setProfile(JSON.parse(storedProfile)); // Set the user in the state
		}
		setLoading(false);
	}, []);

	const login = (tokens, user, profile) => {
		setTokens(tokens);
		setUser(user);
		setProfile(profile);
		localStorage.setItem('user', JSON.stringify(user));
		localStorage.setItem('profile', JSON.stringify(profile));
		localStorage.setItem('tokens', JSON.stringify(tokens));
	};

	const logout = () => {
		setTokens(null);
		setUser(null);
		setProfile(null);
		localStorage.removeItem('tokens');
		localStorage.removeItem('user');
		localStorage.removeItem('profile');
	};

	return (
		<AppContext.Provider value={{ user, profile, setProfile, setUser, tokens, folderId, loading, login, logout, setFolderId }}>
			{children}
		</AppContext.Provider>
	);
};
