import React from 'react';

const InputFieldLight = ({
	type,
	placeholder,
	name,
	value,
	onChange,
	required,
	autoFocus,
	errorMessage, // Added errorMessage prop
	onBlur // Added onBlur prop
}) => {
	return (
		<div className="w-full">
			<input
				type={type}
				name={name}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				onBlur={onBlur} // Handling the blur event
				autoFocus={autoFocus}
				required={required}
				className={`w-full px-4 py-2 border ${errorMessage ? 'border-red-500' : 'border-gray-300'} rounded focus:outline-none focus:border-[#4caf50] text-[#212121] bg-white`}
			/>
			{errorMessage && <p className="text-red-500 text-sm mt-1">{errorMessage}</p>}
		</div>
	);
};

export default InputFieldLight;
