import React, { useState } from 'react';
import ActionButton from './utils/ActionButton';
import axiosInstance from '../axiosInstance';
import { BASE_URL } from '../config';
import InputFieldLight from '../components/utils/InputFieldLight';
import { useTranslation } from 'react-i18next';

const RunnerForm = ({ formData, setFormData, isEditing, editingRunnerId, setRunners, handleCloseModal }) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setErrors({});

        try {
            let response;
            if (isEditing) {
                response = await axiosInstance.put(`${BASE_URL}/api/runners/${editingRunnerId}/`, formData);
                setRunners((prevRunners) =>
                    prevRunners.map((runner) => (runner.id === editingRunnerId ? response.data : runner))
                );
            } else {
                response = await axiosInstance.post(`${BASE_URL}/api/runners/`, formData);
                setRunners((prevRunners) => [...prevRunners, response.data]);
            }
            handleCloseModal();
        } catch (error) {
            if (error.response && error.response.data) {
                setErrors(error.response.data);
            } else {
                console.error(t("runner.save_error"), error);
            }
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <form onSubmit={handleFormSubmit}>
            <div className="mb-4">
                <label className="block text-sm mb-2">{t("runner.name_label")}</label>
                <InputFieldLight
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder={t("runner.name_placeholder")}
                    required
                    autoFocus
                />
                {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
            </div>

            <div className="mb-4">
                <label className="block text-sm mb-2">{t("runner.height_label")}</label>
                <InputFieldLight
                    name="height"
                    type="number"
                    value={formData.height}
                    onChange={handleChange}
                    placeholder={t("runner.height_placeholder")}
                    required
                />
                {errors.height && <p className="text-red-500 text-xs mt-1">{errors.height}</p>}
            </div>

            <div className="mb-4">
                <label className="block text-sm mb-2">{t("runner.weight_label")}</label>
                <InputFieldLight
                    name="weight"
                    type="number"
                    value={formData.weight}
                    onChange={handleChange}
                    placeholder={t("runner.weight_placeholder")}
                    required
                />
                {errors.weight && <p className="text-red-500 text-xs mt-1">{errors.weight}</p>}
            </div>

            <div className="flex justify-end">
                <ActionButton
                    type="submit"
                    label={isEditing ? t("runner.save_changes") : t("runner.submit")}
                />
            </div>
        </form>
    );
};

export default RunnerForm;
