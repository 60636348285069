// src/components/GoogleLoginButton.js
import React, { useContext } from 'react';

import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { AppContext } from '../AppContext';

import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config';

function GoogleLoginButton({ label }) {
	const navigate = useNavigate();

	const { login } = useContext(AppContext);


	const handleSuccess = async (response) => {
		// Extract token
		const token = response.credential;
		console.log(token);
		// Send the token to your backend to register/login the user
		try {
		const res = await axios.post(`${BASE_URL}/api/auth/google-login/`, { token }, {
			headers: {
			'Content-Type': 'application/json',
			},
		});

		if (res.status === 200) {
			const data = res.data;

			// Handle success, such as navigating to another page or setting user data
			login(data.tokens, data.user, data.profile);

			navigate('/home');
		} else {
			console.error('Google login/register failed:', res.data);
		}
		} catch (error) {
			console.error('Error during Google login:', error);
		}
	};

	const handleError = () => {
		console.error('Google Login Failed');
	};

	return (
		<GoogleLogin
			onSuccess={handleSuccess}
			onError={handleError}
			theme="outline"
			size="large"
			type="standard"
			text={label}
			width="380"
		/>
	);
}

export default GoogleLoginButton;
