import React from 'react';
import { Routes, Route, Navigate  } from 'react-router-dom';
import Home from './pages/Home';
import VideoAnalysis from './pages/video/VideoAnalysis';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import VerifyEmail from './pages/auth/VerifyEmail';

import VideoUpload from './pages/video/VideoUpload';
import PublicRoute from './components/PublicRoute';
import ProtectedRoute from './components/ProtectedRoute';

import './index.css';
import './assets/custom.css';
import Layout from './components/Layout';

import PaymentPage from './pages/payments/PaymentPage';
import SettingsPage from './pages/settings/SettingsPage';
import EditProfilePage from './pages/profile/EditProfilePage';
import UserProfilePage from './pages/profile/UserProfilePage';
import RunnersListPage from './pages/runners/RunnersListPage';
import MyAccount from './pages/profile/MyAccount';

import PasswordResetRequest from './pages/auth/PasswordResetRequest';
import PasswordResetConfirm from './pages/auth/PasswordResetConfirm';

import useDynamicTitle from './hooks/useDynamicTitle';


function App() {
    useDynamicTitle();

    return (
        <div>
            <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route
                    path="/login"
                    element={
                    <PublicRoute>
                        <Login />
                    </PublicRoute>
                    }
                />
                <Route
                    path="/register"
                    element={
                    <PublicRoute>
                        <Register />
                    </PublicRoute>
                    }
                />

                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/password-reset" element={<PasswordResetRequest />} />
                <Route path="/password-reset-confirm/:uidb64/:token" element={<PasswordResetConfirm />} />

                {/* Protected Routes */}
                <Route
                    path="/home"
                    element={
                    <ProtectedRoute>
                        <Home />
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/my-account"
                    element={
                    <ProtectedRoute>
                        <Layout>
                            <MyAccount />
                        </Layout>
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/user-profile"
                    element={
                    <ProtectedRoute>
                        <Layout>
                            <UserProfilePage />
                        </Layout>
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/settings"
                    element={
                    <ProtectedRoute>
                        <Layout><SettingsPage /></Layout>
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/runners"
                    element={
                    <ProtectedRoute>
                        <Layout><RunnersListPage /></Layout>
                    </ProtectedRoute>
                    }
                />

                <Route
                    path="/payment"
                    element={
                    <ProtectedRoute>
                        <Layout><PaymentPage /></Layout>
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/edit-profile"
                    element={
                    <ProtectedRoute>
                        <Layout><EditProfilePage /></Layout>
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/folder/:folderId"
                    element={
                    <ProtectedRoute>
                        <Home />
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/video-upload"
                    element={
                    <ProtectedRoute>
                        <VideoUpload />
                    </ProtectedRoute>
                    }
                />
                <Route
                    path="/video-analysis/:videoId"
                    element={
                    <ProtectedRoute>
                        <VideoAnalysis />
                    </ProtectedRoute>
                    }
                />
            </Routes>
        </div>
    );
}

export default App;
