import React from 'react';
import LoadingSpinner from './utils/LoadingSpinner';
import InputFieldLight from './utils/InputFieldLight';
import ActionButton from './utils/ActionButton';
import { useTranslation } from 'react-i18next';

const PasswordChangeModal = ({
    oldPassword,
    setOldPassword,
    newPassword,
    setNewPassword,
    confirmPassword,
    setConfirmPassword,
    errorMessage,
    handlePasswordChange,
    loading,
    setShowModal
}) => {
    const { t } = useTranslation();

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4 z-50">
            <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg w-full max-w-lg space-y-4">
                <h2 className="text-lg md:text-xl font-semibold mb-4">{t('profile.change_password')}</h2>
                {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

                <InputFieldLight
                    type="password"
                    placeholder={t('profile.old_password')}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    className="mb-4"
                    required
                />
                <InputFieldLight
                    type="password"
                    placeholder={t('profile.new_password')}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="mb-4"
                    required
                />

                <InputFieldLight
                    type="password"
                    placeholder={t('profile.confirm_password')}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="mb-4"
                    required
                />

                <div className="flex justify-end space-x-4">
                    <ActionButton
                        onClick={() => setShowModal(false)}
                        label={t('general.cancel')}
                        customStyles="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md"
                    />

                    <ActionButton
                        onClick={handlePasswordChange}
                        label={loading ? <LoadingSpinner /> : t('profile.change_password')}
                        disabled={loading}
                    />
                </div>
            </div>
        </div>
    );
};

export default PasswordChangeModal;
