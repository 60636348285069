// src/utils/unitConversions.js

// Convert height between cm and inches
export const convertHeight = (height, currentUnit) => {
    if (currentUnit === 'cm') {
      return height; // Height is already in cm
    } else if (currentUnit === 'in') {
      return (height / 2.54).toFixed(2); // Convert cm to inches
    }
  };

  // Convert weight between kg and lbs
  export const convertWeight = (weight, currentUnit) => {
    if (currentUnit === 'kg') {
      return weight; // Weight is already in kg
    } else if (currentUnit === 'lbs') {
      return (weight * 2.20462).toFixed(2); // Convert kg to lbs
    }
  };
