// SelectFieldDark.js
import React from 'react';

const SelectFieldDark = ({
    label,
    name,
    value,
    onChange,
    options = [],  // Options passed as an array of objects
    required = false,
    error = false,
    errorMessage = ''
    }) => {
    return (
        <div>
            {label && <label className="block text-gray-400 text-sm mb-2" htmlFor={name}>{label}</label>}
            <select
                name={name}
                value={value}
                onChange={onChange}
                required={required}
                className={`w-full px-4 py-2 border ${error ? 'border-red-500' : 'border-gray-600'} rounded focus:outline-none focus:border-[#4caf50] text-white bg-[#212121]`}
            >
                <option value="">Select an option</option>
                {options.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </select>
            {error && <p className="text-red-500 text-sm mt-1">{errorMessage}</p>}
        </div>
    );
};

export default SelectFieldDark;
