import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { BASE_URL } from '../../config';
import { AppContext } from '../../AppContext';
import axios from 'axios';
import LoadingSpinner from '../../components/utils/LoadingSpinner';
import InputFieldLight from '../../components/utils/InputFieldLight';
import ActionButton from '../../components/utils/ActionButton';
import DividerWithText from '../../components/utils/DividerWithText';
import GoogleLoginButton from '../../components/GoogleLoginButton';
import { useTranslation } from 'react-i18next';

function Login() {
	const { t } = useTranslation();
	const { login } = useContext(AppContext);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			const response = await axios.post(
				`${BASE_URL}/api/auth/login/`,
				{ email, password },
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);

			if (response.status === 200) {
				const data = response.data;
				login(data.tokens, data.user, data.profile);
				navigate('/home');
			} else {
				setErrorMessage(response.data.error || t('authentication.login_failed'));
			}
		} catch (error) {
			if (error.response) {
				setErrorMessage(error.response.data.error || t('authentication.login_failed'));
			} else {
				setErrorMessage(t('authentication.error_logging_in'));
			}
			console.error('Error logging in', error.response);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="flex items-center justify-center h-screen bg-gray-100">
			<div className="w-full max-w-md bg-white p-8 rounded shadow-md">
				<h2 className="text-2xl font-bold mb-6 text-center text-[#4caf50]">
					{t('authentication.login')}
				</h2>
				{errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
				<form onSubmit={handleSubmit} className="space-y-4">
					<InputFieldLight
						type="text"
						placeholder={t('authentication.email')}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						autoFocus
						required
					/>
					<InputFieldLight
						type="password"
						placeholder={t('authentication.password')}
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
					/>
					<div className="text-right" style={{ margin: 0 }}>
						<Link to="/password-reset" className="text-sm text-blue-500 hover:underline">
							{t('authentication.forgot_password')}?
						</Link>
					</div>
					<ActionButton
						onClick={handleSubmit}
						label={loading ? <LoadingSpinner /> : t('authentication.login')}
						type="submit"
						customStyles={`w-full flex items-center justify-center text-white py-2 focus:outline-none ${loading ? 'cursor-not-allowed' : ''}`}
						disabled={loading}
					/>
				</form>
				<p className="text-center mt-4">
					{t('authentication.create_account')}{' '}
					<Link to="/register" className="text-blue-500 hover:underline">
						{t('authentication.register')}
					</Link>
				</p>

				<div className="text-center mt-4">
					<DividerWithText text={t('general.or')} />
					<GoogleLoginButton label={t('authentication.signin_with_google')} />
				</div>
			</div>
		</div>
	);
}

export default Login;
