// SettingsPage.js
import React, {useState, useContext} from 'react';
import axiosInstance from '../../axiosInstance';
import { AppContext } from '../../AppContext';
import { BASE_URL } from '../../config';
import ActionButton from '../../components/utils/ActionButton';
import LoadingSpinner from '../../components/utils/LoadingSpinner';
import { useTranslation } from 'react-i18next';

import PageContainer from '../../components/PageContainer';


function SettingsPage() {
    const { t } = useTranslation();
    const { profile, logout, setProfile } = useContext(AppContext);
    const [language, setLanguage] = useState(profile.language_preference || 'en');
    const [heightUnit, setHeightUnit] = useState(profile.height_unit || 'cm');
    const [weightUnit, setWeightUnit] = useState(profile.weight_unit || 'kg');
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSaveSettings = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.put(
                `${BASE_URL}/api/profile/update-settings/`,
                {
                    language_preference: language,
                    height_unit: heightUnit,
                    weight_unit: weightUnit
                }
            );

            if (response.status === 200) {
                const updatedProfile = {
                    ...profile,
                    language_preference: language,
                    height_unit: heightUnit,
                    weight_unit: weightUnit
                };

                setProfile(updatedProfile);
                localStorage.setItem('profile', JSON.stringify(updatedProfile));
                alert(t('settings.update_success'));
            } else {
                alert(t('settings.update_failed'));
            }
        } catch (error) {
            console.error('Error updating settings', error);
            alert(t('settings.update_error'));
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteAccount = async () => {
        if (!showDeleteConfirm) {
            setShowDeleteConfirm(true);
            return;
        }

        try {
            const response = await axiosInstance.delete(`${BASE_URL}/api/delete-account/`);

            if (response.status === 200) {
                alert(t('settings.delete_success'));
                logout();
            } else {
                alert(t('settings.delete_failed'));
            }
        } catch (error) {
            console.error('Error deleting account', error);
            alert(t('settings.delete_error'));
        }
    };

    const cancelDeleteAccount = () => {
        setShowDeleteConfirm(false);
    };

    return (
        <PageContainer>
            <div className="p-6 bg-[#2D2D2D] shadow-lg rounded-md">
                <h2 className="text-2xl font-semibold text-white mb-6">{t('settings.title')}</h2>
                <div className="space-y-6">
                    {/* Language Preference */}
                    <div>
                        <label className="text-gray-400 text-sm mb-2 block">{t('settings.language')}</label>
                        <select
                            className="w-full px-4 py-2 border border-gray-600 bg-[#2D2D2D] text-white rounded-sm focus:outline-none focus:ring-2 focus:ring-[#4caf50]"
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                        >
                            <option value="en">{t('settings.english')}</option>
                            <option value="es">{t('settings.spanish')}</option>
                            <option value="fr">{t('settings.french')}</option>
                        </select>
                    </div>

                    {/* Height Unit */}
                    <div>
                        <label className="text-gray-400 text-sm mb-2 block">{t('settings.height_unit')}</label>
                        <select
                            className="w-full px-4 py-2 border border-gray-600 bg-[#2D2D2D] text-white rounded-sm focus:outline-none focus:ring-2 focus:ring-[#4caf50]"
                            value={heightUnit}
                            onChange={(e) => setHeightUnit(e.target.value)}
                        >
                            <option value="cm">{t('settings.centimeters')}</option>
                            <option value="in">{t('settings.inches')}</option>
                        </select>
                    </div>

                    {/* Weight Unit */}
                    <div>
                        <label className="text-gray-400 text-sm mb-2 block">{t('settings.weight_unit')}</label>
                        <select
                            className="w-full px-4 py-2 border border-gray-600 bg-[#2D2D2D] text-white rounded-sm focus:outline-none focus:ring-2 focus:ring-[#4caf50]"
                            value={weightUnit}
                            onChange={(e) => setWeightUnit(e.target.value)}
                        >
                            <option value="kg">{t('settings.kilograms')}</option>
                            <option value="lbs">{t('settings.pounds')}</option>
                        </select>
                    </div>

                    <div className="flex justify-end space-x-4 mt-8">
                        <ActionButton
                            onClick={handleSaveSettings}
                            label={loading ? <LoadingSpinner /> : t('settings.save')}
                            customStyles={`bg-[#4caf50] hover:bg-green-600 text-white flex items-center justify-center ${loading ? 'cursor-not-allowed' : ''}`}
                            disabled={loading}
                        />

                        {showDeleteConfirm ? (
                            <div className="flex items-center space-x-4 bg-[#202020] text-white p-4 rounded-md">
                                <p>{t('settings.delete_confirm_message')}</p>
                                <div className="flex space-x-4">
                                    <ActionButton
                                        onClick={cancelDeleteAccount}
                                        label={t('settings.cancel')}
                                    />
                                    <ActionButton
                                        onClick={handleDeleteAccount}
                                        label={t('settings.confirm')}
                                        customStyles="bg-red-600 hover:bg-red-700 text-white"
                                    />
                                </div>
                            </div>
                        ) : (
                            <ActionButton
                                onClick={handleDeleteAccount}
                                label={t('settings.delete_account')}
                                customStyles="bg-red-600 hover:bg-red-700 text-white px-6 py-2 rounded-md"
                            />
                        )}
                    </div>
                </div>
            </div>
        </PageContainer>
    );
}

export default SettingsPage;
