import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../axiosInstance';
import { BASE_URL } from '../../config';

const VideoContent = ({ isSmallScreen, videoSrc, videoRef, canvasRef, bookmarks, setBookmarks, StepsChart, videoId }) => {
	const { t } = useTranslation();
	const [noteModalOpen, setNoteModalOpen] = useState(false);
	const [noteText, setNoteText] = useState('');
	const [noteTime, setNoteTime] = useState(null);
	const [editingBookmarkId, setEditingBookmarkId] = useState(null); // Track if we're editing a bookmark
	const [isMetadataLoaded, setIsMetadataLoaded] = useState(false);

	// Function to open the modal with the current video time (for new note)
	const openNoteModal = () => {
		if (videoRef.current) {
			videoRef.current.pause(); // Pause the video
			const currentTime = videoRef.current.currentTime;
			setNoteTime(currentTime);
			setNoteText('');
			setEditingBookmarkId(null); // Clear editing mode for new note
			setNoteModalOpen(true);
		}
	};

	// Function to open the modal for editing an existing bookmark
	const openEditModal = (bookmark) => {
		setNoteTime(bookmark.timestamp);
		setNoteText(bookmark.notes);
		setEditingBookmarkId(bookmark.id); // Set editing mode with the bookmark ID
		setNoteModalOpen(true);
	};

	// Handler for video metadata loaded
	const handleLoadedMetadata = () => {
		setIsMetadataLoaded(true);
	};

	// Helper function to format time in HH:MM:SS
	const formatTime = (seconds) => {
		const date = new Date(0);
		date.setSeconds(seconds);
		return date.toISOString().substring(11, 19);
	};

	// Function to save or update a note to the database
	const saveNote = async () => {
		try {
			if (editingBookmarkId) {
				// Update existing bookmark
				const response = await axiosInstance.put(`${BASE_URL}/api/videos/video/${videoId}/bookmark/${editingBookmarkId}/`, {
					timestamp: noteTime,
					notes: noteText
				});
				// Update bookmark in local state
				setBookmarks(bookmarks.map(b => (b.id === editingBookmarkId ? response.data : b)));
			} else {
				// Create new bookmark
				const response = await axiosInstance.post(`${BASE_URL}/api/videos/video/${videoId}/bookmark/`, {
					timestamp: noteTime,
					notes: noteText
				});
				setBookmarks([...bookmarks, response.data]); // Add new bookmark to the list
			}
			setNoteModalOpen(false);
			setNoteText('');
		} catch (error) {
			console.error("Error saving note:", error.response?.data || error.message);
		}
	};

	// Function to render bookmark markers on the progress bar
	const renderBookmarks = () => {
		if (!isMetadataLoaded || !videoRef.current || !videoRef.current.duration) return null;
		return bookmarks.map((bookmark) => {
			const position = (bookmark.timestamp / videoRef.current.duration) * 100;
			return (
				<div
					key={bookmark.id}
					onClick={() => openEditModal(bookmark)} // Open modal to edit bookmark
					className="absolute top-0 h-full w-[5px] bg-[#3E4143] cursor-pointer"
					style={{ left: `${position}%`, transform: 'translateY(-50%)' }}
					title={`Note: ${bookmark.notes}`}
				></div>
			);
		});
	};
	const deleteNote = async () => {
		try {
			if (editingBookmarkId) {
				await axiosInstance.delete(`${BASE_URL}/api/videos/video/${videoId}/bookmark/${editingBookmarkId}/delete/`);
				setBookmarks(bookmarks.filter((b) => b.id !== editingBookmarkId)); // Remove deleted bookmark from list
				setNoteModalOpen(false);
			}
		} catch (error) {
			console.error("Error deleting note:", error.response?.data || error.message);
		}
	};

	return (
		<div className={`flex-1 w-full ${isSmallScreen ? 'ml-12' : 'ml-0'} ${isSmallScreen ? 'mr-[4.7rem]' : 'mr-0'}`}>
			<div className="px-5 mt-5">
				<div className="w-full flex flex-col justify-center items-center overflow-hidden">
					<div className="relative">
						{videoSrc ? (
							<video
								ref={videoRef}
								controls
								src={videoSrc}
								crossOrigin="anonymous"
								width="800"
								height="480"
								onLoadedMetadata={handleLoadedMetadata}
							>
								<source src={videoSrc} type="video/mp4" />
								{t('video_content.unsupported_format')}
							</video>
						) : (
							<p className="text-white">{t('video_content.loading_video')}</p>
						)}
						<canvas
							ref={canvasRef}
							className="absolute top-0 left-0"
							width="800" height="480"
							style={{ pointerEvents: 'none' }}
						/>

						{/* Overlay Bookmark Markers */}
						<div className="absolute bottom-4 left-0 right-0 h-2 bg-transparent">
							{renderBookmarks()}
						</div>

						{/* Note-taking button */}
						<button
							onClick={openNoteModal}
							className="absolute top-4 right-4 bg-white rounded-full p-2 shadow-md"
							title="Add Note"
							style={{ zIndex: 10 }}
						>
							<i className="fa fa-sticky-note fa-lg"></i>
						</button>
					</div>
				</div>

				{/* Display list of notes */}
				<div className="card mt-4">
					<div className="mb-5">
						<i className="fa fa-chart-line me-2"></i>
						<span>{t('video_content.graphs')}</span>
					</div>
					<StepsChart />
				</div>
			</div>

			{/* Modal for adding/updating a note */}
			{noteModalOpen && (
				<div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
					<div className="bg-white p-5 rounded-lg shadow-lg w-96">
						<h4 className="text-lg font-semibold mb-3">{editingBookmarkId ? 'Edit Note' : 'Add Note'}</h4>
						<p className="mb-2">Current Time: <strong>{formatTime(noteTime)}</strong></p>
						<textarea
							className="w-full p-2 border border-gray-300 rounded mb-3"
							rows="4"
							value={noteText}
							onChange={(e) => setNoteText(e.target.value)}
							placeholder="Type your note here..."
							required
						></textarea>
						<div className="flex justify-end space-x-2">
							<button
								className="bg-gray-300 p-2 rounded"
								onClick={() => setNoteModalOpen(false)}
							>
								Cancel
							</button>
							{editingBookmarkId && (
								<button
									className="bg-red-500 text-white p-2 rounded"
									onClick={deleteNote}
								>
									Delete Note
								</button>
							)}
							<button
								className="bg-[#4CAF50] text-white p-2 rounded"
								onClick={saveNote}
								disabled={!noteText.trim()}
							>
								{editingBookmarkId ? 'Update Note' : 'Save Note'}
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default VideoContent;
