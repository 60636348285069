import React, { useState, useEffect } from 'react';
import InputFieldLight from '../utils/InputFieldLight';
import { useTranslation } from 'react-i18next';

const PayPal = ({ paypal_email, handleInputChange, serverError }) => {
    const { t } = useTranslation();
    const [emailError, setEmailError] = useState('');

    // Email validation function
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Handle input change with validation
    const handleEmailChange = (e) => {
        const email = e.target.value;
        handleInputChange(e); // Update parent state
        if (!validateEmail(email)) {
            setEmailError(t('paypal.email_invalid')); // Use translation key for invalid email
        } else {
            setEmailError(''); // Clear error when valid
        }
    };

    // Check if server error exists and display it
    useEffect(() => {
        if (serverError) {
            setEmailError(serverError); // Display server error if passed from backend
        }
    }, [serverError]);

    return (
        <InputFieldLight
            type="email"
            name="paypal_email"
            value={paypal_email}
            onChange={handleEmailChange}
            placeholder={t('paypal.email_placeholder')} // Use translation key for placeholder
            required
            className={`${emailError ? 'border-red-500' : ''}`}  // Conditionally apply error style
            errorMessage={emailError}  // Display the error message below the field
        />
    );
};

export default PayPal;
