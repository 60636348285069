import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

function FolderModal({ onClose, onCreate, folder = null }) {
    const { t } = useTranslation();
    const [name, setName] = useState(folder ? folder.name : ''); // Pre-fill the name if folder is passed
    const [error, setError] = useState(''); // State to track and display errors
    const inputRef = useRef(null); // Create a ref for the input field

    useEffect(() => {
        inputRef.current.focus(); // Automatically focus the input field
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Reset error before submitting

        try {
            if (folder) {
                await onCreate(folder.id, name); // Pass the folder ID and the updated name
            } else {
                await onCreate({ name }); // Otherwise, it's a new folder creation
            }
            setName(''); // Clear the input field after submission
        } catch (serverError) {
            setError(serverError.message);
        }
    };

    return (
        <div>
            <h2 className="text-xl font-bold mb-4">
                {folder ? t('folder_management.rename_folder') : t('folder_management.create_new_folder')}
            </h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700">{t('folder_management.folder_name')}</label>
                    <input
                        type="text"
                        value={name}
                        ref={inputRef} // Attach the ref to the input field
                        onChange={(e) => setName(e.target.value)}
                        className={`mt-1 block w-full border border-gray-300 focus:border-[#4caf50] focus:outline-none shadow-sm p-1 ${
                            error ? 'border-red-500' : ''
                        }`} // Add red border on error
                        required
                    />
                    {error && (
                        <p className="text-red-500 text-sm mt-1">{t('folder_management.error_message')}</p> // Display error message
                    )}
                </div>
                <div className="flex justify-end">
                    <button
                        type="button"
                        onClick={onClose}
                        className="bg-gray-500 text-white px-3 py-1 border border-gray-500"
                    >
                        {t('general.cancel')}
                    </button>
                    <button
                        type="submit"
                        className={`${
                            folder ? 'bg-[#4caf50]' : 'bg-[#4caf50]'
                        } text-white px-3 py-1 ml-2 border border-[#4caf50]`}
                    >
                        {folder ? t('general.update') : t('general.create')}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default FolderModal;
