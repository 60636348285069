import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import { BASE_URL } from '../../config';
import ActionButton from '../../components/utils/ActionButton';
import LoadingSpinner from '../../components/utils/LoadingSpinner';
import Modal from '../../components/Modal';
import RunnerForm from '../../components/RunnerForm';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../components/PageContainer';

function RunnersListPage() {
	const { t } = useTranslation();
	const [runners, setRunners] = useState([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [editingRunnerId, setEditingRunnerId] = useState(null);
	const [loadingRunnerId, setLoadingRunnerId] = useState(null);

	const [formData, setFormData] = useState({
		name: '',
		height: '',
		weight: '',
	});

	const navigate = useNavigate();

	useEffect(() => {
		fetchRunners();
	}, []);

	const fetchRunners = async () => {
		try {
			const response = await axiosInstance.get(`${BASE_URL}/api/runners/`);
			setRunners(response.data);
			setLoading(false);
		} catch (error) {
			console.error('Error fetching runners:', error);
			setLoading(false);
		}
	};

	const handleDeleteRunner = async (runnerId) => {
		const confirmed = window.confirm(t("runner.delete_confirm"));

		if (!confirmed) {
			return;
		}

		setLoadingRunnerId(runnerId);
		try {
			const response = await axiosInstance.delete(`${BASE_URL}/api/runners/${runnerId}/`);
			if (response.status === 204) {
				setRunners((prevRunners) => prevRunners.filter((runner) => runner.id !== runnerId));
			} else {
				throw new Error(t("runner.delete_error"));
			}
		} catch (error) {
			console.error(t("runner.delete_error"), error);
		} finally {
			setLoadingRunnerId(null);
		}
	};

	const handleEditRunner = async (runnerId) => {
		try {
			const response = await axiosInstance.get(`${BASE_URL}/api/runners/${runnerId}/`);
			const runnerData = response.data;

			setFormData({
				name: runnerData.name,
				height: runnerData.height,
				weight: runnerData.weight,
			});

			setIsEditing(true);
			setEditingRunnerId(runnerId);
			setIsModalOpen(true);
		} catch (error) {
			console.error(t("runner.edit_error"), error);
		}
	};

	const handleViewFolder = async (runnerId) => {
		try {
			const response = await axiosInstance.get(`${BASE_URL}/api/runners/${runnerId}/folders/`);
			const folderData = response.data;
			navigate(`/folder/${folderData.id}`);
		} catch (error) {
			console.error(t("runner.view_folder_error"), error);
			alert(t("runner.view_folder_not_found"));
		}
	};

	const handleAddRunner = () => {
		setFormData({ name: '', height: '', weight: '' });
		setIsEditing(false);
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
		setFormData({ name: '', height: '', weight: '' });
		setEditingRunnerId(null);
	};

	return (
		<PageContainer>
			<div className="bg-[#2D2D2D] p-6 shadow-lg rounded-md">
				<h2 className="text-xl font-semibold text-white mb-4">{t("runner.my_runners")}</h2>
				<ActionButton
					onClick={handleAddRunner}
					label={t("runner.add_runner")}
					iconClass="fas fa-running"
					customStyles="bg-green-500 hover:bg-green-700"
				/>
				{loading ? (
					<div className="flex justify-center items-center mt-4">
						<LoadingSpinner />
					</div>
				) : error ? (
					<div className="text-red-500 text-center">{t("runner.fetch_error")}</div>
				) : (
					<>
						{runners.length > 0 ? (
							<table className="w-full mt-3">
								<thead>
									<tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-[#212121]">
										<th className="px-4 py-3">{t("runner.name")}</th>
										<th className="px-4 py-3">{t("runner.height")}</th>
										<th className="px-4 py-3">{t("runner.weight")}</th>
										<th className="px-4 py-3">{t("runner.actions")}</th>
									</tr>
								</thead>
								<tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-[#212121]">
									{runners.map((runner) => (
										<tr key={runner.id} className="bg-gray-50 dark:bg-[#212121] text-gray-700 dark:text-gray-400">
											<td className="px-4 py-3 text-sm">{runner.name}</td>
											<td className="px-4 py-3 text-sm">{runner.height}</td>
											<td className="px-4 py-3 text-sm">{runner.weight}</td>
											<td className="px-4 py-3 text-sm">
												<div className="flex space-x-2">
													<button
														onClick={() => handleViewFolder(runner.id)}
														className="text-xs bg-blue-500 hover:bg-blue-700 text-white px-3 py-1 rounded transition-colors duration-300 mr-1"
													>
														{t("runner.view_folders")}
													</button>
													<ActionButton
														onClick={() => handleEditRunner(runner.id)}
														label={t("runner.edit")}
														customStyles="bg-yellow-500 hover:bg-yellow-700 text-xs"
													/>
													<ActionButton
														onClick={() => handleDeleteRunner(runner.id)}
														label={loadingRunnerId === runner.id ? <LoadingSpinner size="small" /> : t("runner.delete")}
														customStyles="bg-red-500 hover:bg-red-700 text-xs"
														disabled={loadingRunnerId === runner.id}
													/>
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						) : (
							<p className="text-white mt-4">{t("runner.no_runners")}</p>
						)}
					</>
				)}
				<Modal isOpen={isModalOpen} onClose={handleCloseModal}>
					<h2 className="text-lg font-semibold mb-4">{isEditing ? t("runner.edit_runner") : t("runner.add_runner")}</h2>
					<RunnerForm
						formData={formData}
						setFormData={setFormData}
						isEditing={isEditing}
						editingRunnerId={editingRunnerId}
						setRunners={setRunners}
						handleCloseModal={handleCloseModal}
					/>
				</Modal>
			</div>
		</PageContainer>
	);
}

export default RunnersListPage;
