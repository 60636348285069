import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AppProvider } from './AppContext'; // Import the provider
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom'; // Import BrowserRouter
import { GoogleOAuthProvider } from '@react-oauth/google'; // Import Google OAuth Provider
import './i18n';

const clientId = '408863066066-8an5fkaiuh6qu0vjv368bb6o4dpjm9fq.apps.googleusercontent.com'; // Replace with your actual Google client ID

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <GoogleOAuthProvider clientId={clientId}>  {/* Add Google OAuth Provider */}
          <AppProvider>
              <Router>
                  <App />
              </Router>
          </AppProvider>
      </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
