import React from 'react';
import personRunningIcon from '../../static/person-running-solid.svg';
import leftIcon from '../../static/ar-left.svg';
import { FRONTEND_BASE_URL } from '../../config';
import { useTranslation } from 'react-i18next';

const Metrics = ({
  isVisible,
  toggleLeftSidebar,
  totalSessionTime,
  stepCount,
  stepLength,
  isSmallScreen,
  firstContact,
  headAngle,
  trunkAngle,
  elbowAngle,
  verticalOscillation,
  fligthTime,
  frontFootAngle,
  backFootAngle,
  FootContactTime
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${
        isVisible
          ? isSmallScreen
            ? 'absolute w-64 z-40 h-full'
            : 'w-2/12'
          : isSmallScreen
          ? 'absolute w-16 z-40 h-full'
          : 'w-0.5/12'
      } transition-all duration-300`}
    >
      <div className="menu_item menu_left align-self-start col-2 order-1">
        <div className="flex justify-between mb-5 pt-2.5">
          {isVisible && <div className="left text-white">{t('metrics.all_metrics')}</div>}
          <div className="title_right">
            <img
              className={`h-6 w-6 head_icon cursor-pointer transform transition-transform duration-300 ${
                isVisible ? '' : 'rotate-180'
              }`}
              src={`${FRONTEND_BASE_URL}${leftIcon}`}
              alt={t('metrics.person_running_icon_alt')}
              onClick={toggleLeftSidebar}
            />
          </div>
        </div>
        {isVisible && (
          <div className="metrics">
            <div className="metric">
              <div className="value">{firstContact}</div>
              <div className="label">{t('metrics.ground_contact_time')}</div>
              <div className="icon_div">
                {/* <img className="met_icon" src={`${FRONTEND_BASE_URL}${personRunningIcon}`} alt={t('metrics.person_running_icon_alt')} /> */}
              </div>
            </div>

            <div className="metric">
              <div className="value">{headAngle}</div>
              <div className="label">{t('metrics.head_position')}</div>
              <div className="icon_div">
                {/* <img className="met_icon" src={`${FRONTEND_BASE_URL}${personRunningIcon}`} alt={t('metrics.person_running_icon_alt')} /> */}
              </div>
            </div>
            <div className="metric">
              <div className="value">{trunkAngle}</div>
              <div className="label">{t('metrics.trunk_position')}</div>
              <div className="icon_div">
                {/* <img className="met_icon" src={`${FRONTEND_BASE_URL}${personRunningIcon}`} alt={t('metrics.person_running_icon_alt')} /> */}
              </div>
            </div>
            <div className="metric">
              <div className="value">{verticalOscillation}</div>
              <div className="label">{t('metrics.vertical_oscillation')}</div>
              <div className="icon_div">
                {/* <img className="met_icon" src={`${FRONTEND_BASE_URL}${personRunningIcon}`} alt={t('metrics.person_running_icon_alt')} /> */}
              </div>
            </div>

            <div className="metric">
              <div className="value">{frontFootAngle}</div>
              <div className="label">{t('metrics.front_foot_angle')}</div>
              <div className="icon_div">
                {/* <img className="met_icon" src={`${FRONTEND_BASE_URL}${personRunningIcon}`} alt={t('metrics.person_running_icon_alt')} /> */}
              </div>
            </div>

            <div className="metric">
              <div className="value">{backFootAngle}</div>
              <div className="label">{t('metrics.back_foot_angle')}</div>
              <div className="icon_div">
                {/* <img className="met_icon" src={`${FRONTEND_BASE_URL}${personRunningIcon}`} alt={t('metrics.person_running_icon_alt')} /> */}
              </div>
            </div>

            <div className="metric">
              <div className="value">{elbowAngle}</div>
              <div className="label">{t('metrics.elbow_angle')}</div>
              <div className="icon_div">
                {/* <img className="met_icon" src={`${FRONTEND_BASE_URL}${personRunningIcon}`} alt={t('metrics.person_running_icon_alt')} /> */}
              </div>
            </div>
            <div className="metric">
              <div className="value">{fligthTime}</div>
              <div className="label">{t('metrics.flight_time')}</div>
              <div className="icon_div">
                {/* <img className="met_icon" src={`${FRONTEND_BASE_URL}${personRunningIcon}`} alt={t('metrics.person_running_icon_alt')} /> */}
              </div>
            </div>
            <div className="metric">
              <div className="value">{FootContactTime}</div>
              <div className="label">{t('metrics.ground_contact_time')}</div>
              <div className="icon_div">
                {/* <img className="met_icon" src={`${FRONTEND_BASE_URL}${personRunningIcon}`} alt={t('metrics.person_running_icon_alt')} /> */}
              </div>
            </div>

            <div className="metric">
              <div className="value">{`${Math.floor(totalSessionTime / 60)}:${('0' + (totalSessionTime % 60)).slice(-2)}`}</div>
              <div className="label">{t('metrics.total_session_time')}</div>
              <div className="icon_div">
                {/* <img className="met_icon" src={`${FRONTEND_BASE_URL}${personRunningIcon}`} alt={t('metrics.person_running_icon_alt')} /> */}
              </div>
            </div>
            <div className="metric">
              <div className="value">{stepCount}</div>
              <div className="label">{t('metrics.steps')}</div>
              <div className="icon_div">
                {/* <img className="met_icon" src={`${FRONTEND_BASE_URL}${personRunningIcon}`} alt={t('metrics.person_running_icon_alt')} /> */}
              </div>
            </div>
            <div className="metric">
              <div className="value">{stepLength} m</div>
              <div className="label">{t('metrics.step_length')}</div>
              <div className="icon_div">
                {/* <img className="met_icon" src={`${FRONTEND_BASE_URL}${personRunningIcon}`} alt={t('metrics.person_running_icon_alt')} /> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Metrics;
